<template>
  <v-sheet class="interface-sumsub fill-height" color="transparent">
    <v-container id="sumsub-websdk-container" class="fill-height">
      <v-row v-if="'complete' !== status.level" class="fill-height" align="center" justify="center">
        <v-col class="d-flex justify-center align-center">
          <LoadersFlamingo :size="loaderSize" />
        </v-col>
      </v-row>
      <PartialsApprovedEvidence v-if="'complete' === status.level" />
    </v-container>
  </v-sheet>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, onBeforeUnmount, watch } from 'vue'
import snsWebSdk from '@sumsub/websdk'
import { useI18n } from 'vue-i18n'
import { getDebugger } from '~/libs/debug'
import LoadersFlamingo from '~/components/loaders/flamingo.vue'
import PartialsApprovedEvidence from '~/components/partials/approvedEvidence.vue'
import { useDisplay } from 'vuetify'
import { useStatusStore } from '~/stores/status'
import type Swal from 'sweetalert2'
import type { EventPayload } from '@sumsub/websdk/types'
import type { SnsWebSdk } from '@sumsub/websdk'

const debug = getDebugger('interfaces:sumsub', '#25b793', '#FFFFFF')

import type { Axios } from 'axios'
import type { MiliCron } from '@jakguru/milicron'
export default defineComponent({
  name: 'InterfacesSumsub',
  components: {
    LoadersFlamingo,
    PartialsApprovedEvidence,
  },
  setup() {
    const status = useStatusStore()
    const display = useDisplay()
    const smAndDown = computed(() => display.smAndDown.value)
    const loaderSize = computed(() => (smAndDown.value ? 200 : 500))
    const i18n = useI18n({ useScope: 'global' })
    const { t } = i18n
    const api = inject<Axios>('api')
    const swal = inject<typeof Swal>('swal')
    const toast = inject<typeof Swal>('toast')
    const cron = inject<MiliCron>('cron')
    const loaded = ref(false)
    const loading = ref(false)
    const refreshable = ref(false)
    watch(
      () => refreshable.value,
      (is) => {
        debug('Refreshable', is)
      },
      {
        immediate: true,
      }
    )
    const token = ref<string | undefined>(undefined)
    let getAccessTokenAbortController: AbortController | undefined
    const getAccessToken = async (onBadResponse: (status: number) => void = () => {}) => {
      if (!api) {
        return ''
      }
      if (getAccessTokenAbortController) {
        getAccessTokenAbortController.abort()
      }
      getAccessTokenAbortController = new AbortController()
      loading.value = true
      const { status, data } = await api.get('/app/sumsub/token', {
        signal: getAccessTokenAbortController.signal,
      })
      loading.value = false
      loaded.value = true
      if (201 !== status) {
        if (403 === status) {
          toast?.fire({
            title: t('errors.provider.wrong.title'),
            text: t('errors.provider.wrong.text'),
            icon: 'error',
          })
          // here we need to trigger a "status" update on the status store
        } else {
          onBadResponse(status)
        }
        return ''
      }
      const { token } = data.payload
      return token
    }
    const onReadyCallback = () => {
      debug('WebSDK is Ready')
      if (window && window.document && document) {
        const iframes = document.querySelectorAll('iframe')
        if (iframes) {
          for (let i = 0; i < iframes.length; i++) {
            const iframe = iframes[i]
            iframe.setAttribute('data-hj-allow-iframe', '')
          }
        }
      }
    }
    const onInitializedCallback = () => {
      debug('WebSDK is Initialized')
    }
    const onStepInitiatedCallback = (payload: EventPayload<'idCheck.onStepInitiated'>) => {
      debug('WebSDK Step Initiated', payload)
    }
    const onStepCompletedCallback = (payload: EventPayload<'idCheck.onStepCompleted'>) => {
      debug('WebSDK Step Completed', payload)
    }
    const onApplicantLoadedCallback = (payload: EventPayload<'idCheck.onApplicantLoaded'>) => {
      debug('WebSDK Applicant Loaded', payload)
    }
    const onApplicantSubmittedCallback = (
      payload: EventPayload<'idCheck.onApplicantSubmitted'>
    ) => {
      debug('WebSDK Applicant Submitted', payload)
      refreshable.value = true
    }
    const onApplicantResubmittedCallback = (
      payload: EventPayload<'idCheck.onApplicantResubmitted'>
    ) => {
      debug('WebSDK Applicant Resubmitted', payload)
      debug('WebSDK Applicant Submitted', payload)
      refreshable.value = true
    }
    const onActionSubmittedCallback = (payload: EventPayload<'idCheck.onActionSubmitted'>) => {
      debug('WebSDK Action Submitted', payload)
    }
    const actionCompletedCallback = (payload: EventPayload<'idCheck.onActionCompleted'>) => {
      debug('WebSDK Action Completed', payload)
    }
    const moduleResultPresentedCallback = (
      payload: EventPayload<'idCheck.onModuleResultPresented'>
    ) => {
      debug('WebSDK Module Result Presented', payload)
    }
    const onResizeCallback = (payload: EventPayload<'idCheck.onResize'>) => {
      debug('WebSDK Resize', payload)
      if (api && !status.processing) {
        debug('Attempting to Refresh information from background')
        status.fetch(api)
      }
    }
    const onVideoIdentCallStartedCallback = (
      payload: EventPayload<'idCheck.onVideoIdentCallStarted'>
    ) => {
      debug('WebSDK Video Ident Call Started', payload)
    }
    const onVideoIdentModeratorJoinedCallback = (
      payload: EventPayload<'idCheck.onVideoIdentModeratorJoined'>
    ) => {
      debug('WebSDK Video Ident Moderator Joined', payload)
    }
    const onVideoIdentCompletedCallback = (
      payload: EventPayload<'idCheck.onVideoIdentCompleted'>
    ) => {
      debug('WebSDK Video Ident Completed', payload)
    }
    const onUploadErrorCallback = (payload: EventPayload<'idCheck.onUploadError'>) => {
      debug('WebSDK Upload Error', payload)
    }
    const onUploadWarningCallback = (payload: EventPayload<'idCheck.onUploadWarning'>) => {
      debug('WebSDK Upload Warning', payload)
    }
    let instance: SnsWebSdk | undefined
    const launchWebSdk = () => {
      if (!token.value) {
        return
      }
      if ('complete' === status.level) {
        return
      }
      instance = snsWebSdk
        .init(token.value, () => getAccessToken())
        .withConf({
          lang: i18n.locale.value,
          theme: 'light',
        })
        .withOptions({ addViewportTag: false, adaptIframeHeight: true })
        .on('idCheck.onReady', onReadyCallback)
        .on('idCheck.onInitialized', onInitializedCallback)
        .on('idCheck.onStepInitiated', onStepInitiatedCallback)
        .on('idCheck.onStepCompleted', onStepCompletedCallback)
        .on('idCheck.onApplicantLoaded', onApplicantLoadedCallback)
        .on('idCheck.onApplicantSubmitted', onApplicantSubmittedCallback)
        .on('idCheck.onApplicantResubmitted', onApplicantResubmittedCallback)
        .on('idCheck.onActionSubmitted', onActionSubmittedCallback)
        .on('idCheck.onResize', onResizeCallback)
        .on('idCheck.onVideoIdentCallStarted', onVideoIdentCallStartedCallback)
        .on('idCheck.onVideoIdentModeratorJoined', onVideoIdentModeratorJoinedCallback)
        .on('idCheck.onVideoIdentCompleted', onVideoIdentCompletedCallback)
        .on('idCheck.onUploadError', onUploadErrorCallback)
        .on('idCheck.onUploadWarning', onUploadWarningCallback)
        .build()

      instance.launch('#sumsub-websdk-container')
    }
    watch(
      () => token.value,
      (is, _was) => {
        if ('string' === typeof is && is.length > 0) {
          launchWebSdk()
        }
      },
      {
        immediate: true,
      }
    )
    watch(
      () => status.level,
      (is, was) => {
        refreshable.value = false
        if ('undefined' === typeof was) {
          // ignore the first change
          return
        }
        if (is === was) {
          // ignore the same value
          return
        }
        if (instance) {
          instance.destroy()
          instance = undefined
          if ('complete' === is) {
            return
          }
          getAccessToken()
            .then((t: string) => {
              if ('string' !== typeof t || !t) {
                return
              }
              debug('Loaded New Initial Access Token', t)
              token.value = t
            })
            .catch((error) => {
              debug('Error New Loading Initial Access Token', error)
            })
        }
        if ('complete' !== is && !instance) {
          getAccessToken()
            .then((t: string) => {
              if ('string' !== typeof t || !t) {
                return
              }
              debug('Loaded New Initial Access Token', t)
              token.value = t
            })
            .catch((error) => {
              debug('Error New Loading Initial Access Token', error)
            })
        }
      },
      {
        immediate: true,
      }
    )
    const onCron = () => {
      if (refreshable.value) {
        debug('Refreshing information from background')
        status.fetch(api!)
      }
    }
    onMounted(() => {
      cron!.$on('*/30 * * * * *', onCron)
      debug('Loading Initial Access Token')
      getAccessToken((status) => {
        if (status === 418) {
          return
        }
        if (status !== 403) {
          swal?.fire({
            title: t('errors.sumsub.token.title'),
            text: t('errors.sumsub.token.text'),
            icon: 'error',
          })
        }
      })
        .then((t: string) => {
          if ('string' !== typeof t || !t) {
            return
          }
          debug('Loaded Initial Access Token', t)
          token.value = t
        })
        .catch((error) => {
          debug('Error Loading Initial Access Token', error)
        })
    })
    onBeforeUnmount(() => {
      if (instance) {
        instance.destroy()
      }
      if (getAccessTokenAbortController) {
        getAccessTokenAbortController.abort()
      }
      cron!.$off('*/30 * * * * *', onCron)
    })
    return {
      loaderSize,
      status,
    }
  },
})
</script>

<style>
#sumsub-websdk-container {
  justify-content: center;
  iframe {
    @media (min-width: 560px) {
      max-width: 500px;
    }
  }
}
</style>
