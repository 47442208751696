<template>
  <v-app class="layout-authenticated">
    <PartialsHeader v-if="showHeader">
      <template #left>
        <v-toolbar-items class="d-flex w-100 h-100 justify-start">
          <v-btn variant="text" @click="onLogoutRequest">
            <v-icon>mdi-logout</v-icon>
            <component :is="mobileLiveChatTextWrapper" class="ms-2">
              {{ $t('buttons.logout') }}
            </component>
          </v-btn>
        </v-toolbar-items>
      </template>
      <template #right>
        <v-toolbar-items class="d-flex w-100 h-100 justify-end">
          <v-btn v-if="canRequestPush" icon @click="showPushSnackbar = true" size="small">
            <v-badge color="error" overlap dot :model-value="true" :rounded="false">
              <v-icon color="secondary">mdi-bell</v-icon>
            </v-badge>
          </v-btn>
          <v-btn variant="text" @click="onLiveChatRequest">
            <component :is="mobileLiveChatTextWrapper">{{ mobileLiveChatText }}</component>
          </v-btn>
        </v-toolbar-items>
      </template>
    </PartialsHeader>
    <PartialsSidbar v-if="showSideBar">
      <template #left>
        <v-toolbar-items class="d-flex w-100 h-100 justify-start">
          <v-btn color="secondary" variant="text" @click="onLogoutRequest">
            <v-icon>mdi-logout</v-icon>
            <component :is="nonMobileLiveChatTextWrapper" class="ms-2">
              {{ $t('buttons.logout') }}
            </component>
          </v-btn>
        </v-toolbar-items>
      </template>
      <template #right>
        <v-toolbar-items class="d-flex w-100 h-100 justify-end">
          <v-btn v-if="canRequestPush" icon @click="showPushSnackbar = true">
            <v-badge color="error" overlap dot :model-value="true" :rounded="false">
              <v-icon color="secondary">mdi-bell</v-icon>
            </v-badge>
          </v-btn>
          <v-btn color="secondary" variant="text" @click="onLiveChatRequest">
            <component :is="nonMobileLiveChatTextWrapper">{{ nonMobileLiveChatText }}</component>
          </v-btn>
        </v-toolbar-items>
      </template>
    </PartialsSidbar>
    <v-main v-if="'default' === guiToShow">
      <slot></slot>
    </v-main>
    <v-main v-else-if="'sumsub' === guiToShow">
      <InterfacesSumsub />
    </v-main>
    <v-main v-else>
      <v-container class="fill-height">
        <v-row class="fill-height" align="center" justify="center">
          <v-col class="d-flex justify-center align-center">
            <LoadersFlamingo :size="loaderSize" />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-snackbar
      v-model="showPushSnackbar"
      position="fixed"
      multi-line
      :timeout="10000"
      color="surface"
      location="top"
      :density="smAndDown ? 'compact' : 'default'"
    >
      <v-list-item two-line density="compact">
        <v-list-item-title v-if="!smAndDown">{{
          $t('hints.push.title', { brand: cnfg.brand })
        }}</v-list-item-title>
        <v-list-item-subtitle v-if="!smAndDown">{{
          $t('hints.push.message', { brand: cnfg.brand })
        }}</v-list-item-subtitle>
        <v-list-item-title v-if="smAndDown">{{
          $t('hints.push.titleShort', { brand: cnfg.brand })
        }}</v-list-item-title>
        <v-list-item-subtitle v-if="smAndDown">{{
          $t('hints.push.messageShort', { brand: cnfg.brand })
        }}</v-list-item-subtitle>
      </v-list-item>
      <template v-slot:actions>
        <v-btn color="success" variant="text" @click="onPushAccept">{{
          $t('buttons.yes', { brand: cnfg.brand })
        }}</v-btn>
        <v-btn color="error" variant="text" @click="onPushDecline">{{
          $t('buttons.no', { brand: cnfg.brand })
        }}</v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script lang="ts">
/**
 * This layout is the "login" screen for unauthenticated users.
 */
import { defineComponent, inject, computed, ref, onMounted } from 'vue'
import { useDisplay } from 'vuetify'
import { useI18n } from 'vue-i18n'
import { useStatusStore } from '~/stores/status'
import PartialsHeader from '~/components/partials/header.vue'
import PartialsSidbar from '~/components/partials/sidebar.vue'
import InterfacesSumsub from '~/components/interfaces/sumsub.vue'

import type { Bus } from '~/libs/bus'
import type { Identity } from '~/libs/identity'
import type { PushService } from '~/libs/push'
import type { Axios } from 'axios'

export default defineComponent({
  name: 'AuthenticatedLayout',
  components: {
    PartialsHeader,
    PartialsSidbar,
    InterfacesSumsub,
  },
  setup() {
    const runtimeConfig = useRuntimeConfig()
    const display = useDisplay()
    const { t } = useI18n({ useScope: 'global' })
    const xs = computed(() => display.xs.value)
    const smAndDown = computed(() => display.smAndDown.value)
    const mdAndDown = computed(() => display.mdAndDown.value)
    const bus = inject<Bus>('bus')
    const identity = inject<Identity>('identity')
    const push = inject<PushService>('push')
    const api = inject<Axios>('api')
    const canRequestPush = computed(() => push && push.canRequestPermission.value)
    const onLiveChatRequest = () => {
      if (bus) {
        bus.emit('livechat:open', { local: true, crossTab: true })
      }
    }
    const mobileLiveChatTextWrapper = computed(() => (xs.value ? 'small' : 'span'))
    const mobileLiveChatText = computed(() =>
      smAndDown.value ? t('buttons.chat') : t('buttons.livechat')
    )
    const nonMobileLiveChatTextWrapper = computed(() => (mdAndDown.value ? 'small' : 'span'))
    const nonMobileLiveChatText = computed(() =>
      mdAndDown.value || canRequestPush.value ? t('buttons.chat') : t('buttons.livechat')
    )
    const onLogoutRequest = () => {
      if (identity) {
        identity.logout()
      }
    }
    const showPushSnackbar = ref(false)
    const onPushAccept = () => {
      if (!push) {
        return
      }
      push.requestPushPermission()
      showPushSnackbar.value = false
    }
    const onPushDecline = () => {
      if (!push) {
        return
      }
      push.doNotRequestPushPermission()
      showPushSnackbar.value = false
    }
    const store = useStatusStore()
    const guiToShow = computed(() => (null === store.raw || !store.loaded ? 'loading' : store.gui))
    const showHeader = computed(() => {
      if (null === store.raw || !store.loaded) {
        return false
      }
      switch (guiToShow.value) {
        case 'sumsub':
          return true
        default:
          return smAndDown.value
      }
    })
    const showSideBar = computed(() => {
      if (null === store.raw || !store.loaded) {
        return false
      }
      switch (guiToShow.value) {
        case 'sumsub':
          return false
        default:
          return !smAndDown.value
      }
    })
    const loaderSize = computed(() => (smAndDown.value ? 200 : 500))
    onMounted(() => {
      if (api) {
        store.fetch(api)
      }
    })
    return {
      cnfg: runtimeConfig.public,
      smAndDown,
      onLiveChatRequest,
      mobileLiveChatTextWrapper,
      mobileLiveChatText,
      nonMobileLiveChatTextWrapper,
      nonMobileLiveChatText,
      onLogoutRequest,
      canRequestPush,
      showPushSnackbar,
      onPushAccept,
      onPushDecline,
      guiToShow,
      store,
      showHeader,
      showSideBar,
      loaderSize,
    }
  },
})
</script>

<style lang="scss">
.layout-authenticated {
  .product-text {
    text-transform: uppercase !important;
  }
}
</style>
