<template>
  <v-navigation-drawer
    app
    flat
    color="background"
    :border="4"
    disable-resize-watcher
    disable-route-watcher
    :mobile-breakpoint="0"
    permanent
    touchless
    :width="width"
    class="partial-sidebar"
  >
    <v-toolbar flat color="transparent" :density="density">
      <v-container class="fill-height pa-0">
        <v-row no-gutters style="height: 100%; max-height: 100%">
          <v-col cols="4" style="height: 100%; max-height: 100%">
            <slot name="left"></slot>
          </v-col>
          <v-col
            cols="4"
            style="max-height: 100%; display: flex; justify-content: center; align-items: center"
            :class="logoPaddingClass"
          >
            <v-responsive
              :ratio="500 / 71"
              style="max-height: 100%; max-width: 100%"
              class="text-center"
            >
              <img
                src="~/assets/logo.svg"
                :alt="
                  $t('meta.title', {
                    brand: cnfg.brand,
                  })
                "
                style="max-height: 100%; max-width: 100%"
              />
            </v-responsive>
          </v-col>
          <v-col cols="4" style="height: 100%; max-height: 100%">
            <slot name="right"></slot>
          </v-col>
        </v-row>
      </v-container>
    </v-toolbar>
    <PartialsNav />
  </v-navigation-drawer>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useDisplay } from 'vuetify'
import PartialsNav from './nav.vue'

export default defineComponent({
  name: 'PartialsSidebar',
  components: { PartialsNav },
  setup() {
    const runtimeConfig = useRuntimeConfig()
    const display = useDisplay()
    const mdAndDown = computed(() => display.mdAndDown.value)
    const density = computed(() => (mdAndDown.value ? 'compact' : 'default'))
    const logoPaddingClass = computed(() => (mdAndDown.value ? 'py-1' : 'py-3'))
    const width = computed(() => display.width.value * 0.3)
    return {
      cnfg: runtimeConfig.public,
      density,
      logoPaddingClass,
      width,
    }
  },
})
</script>

<style lang="scss">
.partial-sidebar {
  border-right-width: 5px;
}
</style>
